import useGlobalSettings from '@components/_hooks/useGlobalSettings';
import Image from 'next/image'
import Link from 'next/link';

const HeaderLogo = () => {
    const { getSetting } = useGlobalSettings();
    const width = getSetting('header_logo_width', 180);
    const height = getSetting('header_logo_height', 60);

    return (
        <Link href='/'>
            <div style={{
                height: `70px`,
                width: `60px`,
                position: 'absoltute'
            }}>
                <Image style={{objectFit: 'contain'}} height={height} width={width} priority={true} src={ `/images/${process.env.brand.acronym}/logo-main.png` } alt={'logo-main'} quality={75}/>

            </div>
        </Link>
    )
}

export default HeaderLogo